<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Class' : 'Add New Class'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Program" label-for="program" label-class="form-label">
        <v-select
          id="program"
          class="form-control v-select-custom"
          :class="!$v.program.required && $v.program.$dirty ? 'form-control is-invalid' : ''"
          label="title"
          v-model="program"
          :reduce="program => program.id"
          placeholder="Select Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
          @input="programChanged"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                <md-icon v-else>local_library</md-icon>
              </fmv-avatar>
              <span>{{ option.title }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.title }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.program.required && $v.program.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group v-if="[PROGRAM_TYPE.ONSITE, PROGRAM_TYPE.BLENDED].includes(programType)">
        <label class="form-label"
          >Location <a href="#" @click.prevent="modals.showPostLocationModal = true">+Add New?</a></label
        >
        <v-select
          label="id"
          :options="allLocations"
          class="form-control v-select-custom"
          :class="!$v.location.required && $v.location.$dirty ? 'form-control is-invalid' : ''"
          v-model="location"
          :reduce="lc => lc.id"
          placeholder="Enter Location"
          :loading="areLocationsLoading"
          :filter-by="filterLocations"
        >
          <template slot="option" slot-scope="option">
            {{ get(option, 'address') }}, {{ get(option, 'city') }},

            {{ get(option, 'state') }}
            {{ get(option, 'zipcode') }},
            {{ get(option, 'country') }}
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ get(option, 'address') }}, {{ get(option, 'city') }},

            {{ get(option, 'state') }}
            {{ get(option, 'zipcode') }},
            {{ get(option, 'country') }}
          </template>
        </v-select>

        <b-form-invalid-feedback :state="!$v.location.required && $v.location.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        label="Class URL"
        label-for="class_link"
        label-class="form-label"
        v-if="[PROGRAM_TYPE.VIRTUAL, PROGRAM_TYPE.BLENDED].includes(programType)"
      >
        <b-form-input
          id="class_link"
          placeholder="Enter Class Link"
          v-model="class_link"
          :state="(!$v.class_link.required || !$v.class_link.url) && $v.class_link.$dirty ? false : null"
        />
        <b-form-invalid-feedback v-if="!$v.class_link.url">
          Not a valid link. Please make sure to enter a valid link e.g. https://www.example.com.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="limited_seats" name="check-button" switch>Limit Seats</b-form-checkbox>
      </b-form-group>

      <b-form-group label="Available Seats" label-for="available_seats" label-class="form-label" v-if="limited_seats">
        <b-form-input
          id="available_seats"
          v-model="available_seats"
          type="number"
          placeholder="Enter Number of Avilable Seats"
          :state="!$v.available_seats.required && $v.available_seats.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Instructors" label-for="Instructors" label-class="form-label">
        <v-select
          id="type"
          class="form-control v-select-custom"
          label="first_name"
          v-model="instructors"
          :reduce="ins => ins.id"
          placeholder="Select instructors"
          :options="allInstructors"
          :loading="areInstructorsLoading"
          :class="!$v.instructors.required && $v.instructors.$dirty ? 'form-control is-invalid' : ''"
          multiple
        >
          <template slot="option" slot-scope="option"> {{ option.first_name }} {{ option.last_name }} </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.first_name }} {{ option.last_name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.instructors.required && $v.instructors.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Type" label-for="class-self-paced" label-class="form-label" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="class-self-paced"
          v-model="classSelfPaced"
          :options="typeOptions"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>

      <div class="row" v-if="classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED">
        <b-form-group label="Start Date" label-for="start_date" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="start_date"
            format="DD MMMM, YYYY"
            valueType="YYYY-MM-DD"
            style="width: 100%"
            id="start_date"
            lang="en"
            placeholder="Enter Start Date"
            :state="!$v.start_date.required && $v.start_date.$dirty ? false : null"
            :input-class="
              `mx-input ${!$v.start_date.required && $v.start_date.$dirty ? 'form-control is-invalid' : ''}`
            "
            :disabled="isFormLoading"
            @input="setStartDate"
          ></date-picker>
          <b-form-invalid-feedback :state="!$v.start_date.required && $v.start_date.$dirty ? false : null">
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="End Date" label-for="end_date" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="end_date"
            format="DD MMMM, YYYY"
            valueType="YYYY-MM-DD"
            style="width: 100%"
            id="end_date"
            lang="en"
            placeholder="Enter End Date"
            :state="!$v.end_date.required && $v.end_date.$dirty ? false : null"
            :input-class="
              `mx-input ${
                (!$v.end_date.required || !$v.end_date.afterStartDate) && $v.end_date.$dirty
                  ? 'form-control is-invalid'
                  : ''
              }`
            "
            :disabled="isFormLoading"
          ></date-picker>
          <b-form-invalid-feedback :state="!$v.end_date.required && $v.end_date.$dirty ? false : null">
            This field is required.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="!$v.end_date.afterStartDate && $v.end_date.$dirty ? false : null">
            End date must be after start date.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group label="Total Hours" label-for="total_hours" label-class="form-label">
        <currency-input
          id="amount"
          v-model="total_hours"
          class="form-control"
          :placeholder="
            classSelfPaced ? `Enter total hours` : `Enter total hours (leave empty to calculate automatically)`
          "
          :allow-negative="false"
          :precision="1"
          :currency="null"
        />
      </b-form-group>
      <b-form-group label="Orientation Date" label-for="orientation_date" label-class="form-label">
        <date-picker
          v-model="orientation_date"
          format="DD MMMM, YYYY"
          valueType="YYYY-MM-DD"
          style="width: 100%"
          id="orientation_date"
          lang="en"
          placeholder="MM/DD/YYYY"
        ></date-picker>
      </b-form-group>

      <b-form-group
        v-if="classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED"
        label="Schedule"
        label-for="schedule_type"
        label-class="form-label"
      >
        <v-select
          id="gender"
          class="form-control v-select-custom"
          label="text"
          v-model="schedule_type"
          :reduce="item => item.value"
          placeholder="Select Schedule Type"
          :options="scheduleTypeOptions"
          :class="!$v.schedule_type.required && $v.schedule_type.$dirty ? 'form-control is-invalid' : ''"
        >
        </v-select>
        <b-form-invalid-feedback :state="!$v.schedule_type.required && $v.schedule_type.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <page-separator v-if="classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED">
        <b-link v-b-toggle="'schedule-section'" class="accordion__toggle p-0">
          <span class="flex">Schedule</span>
          <md-icon class="accordion__toggle-icon">
            {{ schdulesVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        v-if="classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED"
        id="schedule-section"
        accordion="accordionId"
        :visible="schdulesVisible"
        class="accordion__menu"
        @hide="schdulesVisible = false"
        @show="schdulesVisible = true"
      >
        <div class="row align-items-center">
          <div class="col-lg-auto mb-2">
            <b-button
              size="sm"
              class="mr-2"
              @click="selectAllRows"
              v-if="classSchedules.length > 0 && selected.length === 0"
              >Select all</b-button
            >
            <b-button
              size="sm"
              class="mr-2"
              @click="clearSelected"
              v-if="classSchedules.length > 0 && selected.length > 0"
              >Clear</b-button
            >
            <b-button size="sm" variant="danger" v-if="selected.length > 0" @click="deleteSelected"
              >Delete Selected</b-button
            >
          </div>
          <div class="col-lg d-flex flex-wrap justify-content-end mb-2">
            <b-btn
              :class="{ 'mb-2': isMobileScreen }"
              variant="primary"
              :disabled="!(program && start_date && end_date && isScheduleEmpty && programStartTime && programEndTime)"
              @click.prevent="openAutoGenModal"
              size="sm"
            >
              <span>Auto-Generate Schedule</span>
            </b-btn>
            <b-btn variant="primary" :disabled="isLoading" @click.prevent="openScheduleModal()" size="sm" class="ml-2">
              <span>Add Custom Schedule</span>
            </b-btn>
          </div>
        </div>
        <div class="mb-2">
          <b-table
            v-if="classSchedules.length > 0"
            :fields="scheduleTableColumns"
            :items="classSchedules"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap mt-2"
            hover
            responsive
            no-local-sorting
            select-mode="multi"
            ref="selectableTable"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <md-icon>check</md-icon>
              </template>
            </template>

            <template #cell(start_time)="data">
              <span v-if="data.item.is_self_paced">
                <i>Self-paced</i>
              </span>
              <span v-else
                >{{ formatTime(data.item.start_time) }}
                {{ formatTimeZone(getLoggedInUser.linked_entity.time_zone) }}</span
              >
            </template>
            <template #cell(end_time)="data">
              <span v-if="data.item.is_self_paced">
                <i>Self-paced</i>
              </span>
              <span v-else
                >{{ formatTime(data.item.end_time) }}
                {{ formatTimeZone(getLoggedInUser.linked_entity.time_zone) }}</span
              >
            </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="openScheduleModal(data.item, data.index)"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </a>
              <a href="#" @click.prevent="removeExpEduItem(data.index)" v-b-popover.hover.right :title="'Remove'">
                <i class="mr-1 material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>
          <b-form-invalid-feedback
            v-if="(!$v.classSchedules.required || !$v.classSchedules.minLength) && $v.classSchedules.$dirty"
            :state="
              (!$v.classSchedules.required || !$v.classSchedules.minLength) && $v.classSchedules.$dirty ? false : null
            "
            >There isn't any schedule added. Please add class schedule.</b-form-invalid-feedback
          >
          <i v-else-if="!classSchedules.length">There isn't any schedule added.</i>
        </div>
      </b-collapse>

      <!-- Payment Plan -->

      <page-separator v-if="classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED">
        <b-link v-b-toggle="'payment-plan-section'" class="accordion__toggle p-0">
          <span class="flex">Payment Plan</span>
          <md-icon class="accordion__toggle-icon">
            {{ paymentPlanVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <!-- v-if="classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED" -->
      <b-collapse
        id="payment-plan-section"
        accordion="paymentAccordionId"
        :visible="paymentPlanVisible"
        class="accordion__menu"
        @hide="paymentPlanVisible = false"
        @show="paymentPlanVisible = true"
      >
        <div class="d-flex justify-content-end mb-2">
          <b-btn variant="primary" :disabled="isLoading" @click.prevent="openPaymentModal()" size="sm">
            <span>Add Payment Plan</span>
          </b-btn>
        </div>
        <div class="mb-2">
          <b-table
            v-if="paymentPlan.length > 0"
            :fields="paymentPlanTableColumns"
            :items="paymentPlan"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
          >
            <template #cell(amount)="data">
              <span v-if="paymentPlanAmountType === PAYMENT_TYPE.PERCENTAGE"
                >{{ $n(computePlanAmount(data.item.amount), 'currency', 'en-US') }} ({{ $n(data.item.amount) }}%)</span
              >
              <span v-else>{{ $n(data.item.amount, 'currency', 'en-US') }}</span>
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="openPaymentModal(data.item, data.index)"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </a>
              <a href="#" @click.prevent="removePaymentItem(data.index)" v-b-popover.hover.right :title="'Remove'">
                <i class="mr-1 material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <b-form-invalid-feedback
            v-if="!$v.paymentPlan.calculateTotalPercentage && $v.paymentPlan.$dirty"
            :state="!$v.paymentPlan.calculateTotalPercentage && $v.paymentPlan.$dirty ? false : null"
          >
            Payment percentage sum is not equal to 100. Please make sure that the total tuition percentage is 100%.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-else-if="!$v.paymentPlan.calculateTotalAmount && $v.paymentPlan.$dirty"
            :state="!$v.paymentPlan.calculateTotalAmount && $v.paymentPlan.$dirty ? false : null"
          >
            {{
              `Payment sum is not equal to tuition fee. Please make sure that the sum must be equal to total tuition fee ${$n(
                get(programSelected, 'tuition_fee', 0),
                'currency',
                'en-US'
              )}.`
            }}
          </b-form-invalid-feedback>

          <i v-else-if="!paymentPlan.length">There isn't any payment plan added.</i>
        </div>
      </b-collapse>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
    <class-sch-modal
      :show-modal="modals.showClassSchModal"
      :class-sch="modals.selectedClassSch"
      :class-sch-index="modals.selectedClassSchIndex"
      :class-sch-arr="modals.classSchArray"
      :start-date="modals.startDate"
      :end-date="modals.endDate"
      @close="hideClassSchModal"
      @closeUpdate="hideUpdateClassSchModal"
    />
    <payment-plan-modal
      :show-modal="modals.showClassPayModal"
      :type="paymentPlanAmountType"
      :payment="modals.selectedPayment"
      :payment-index="modals.selectedPaymentIndex"
      :isClassSelfPaced="classSelfPaced"
      :program-tuition="get(programSelected, 'tuition_fee', 0)"
      @close="hidePaymentModal"
      @closeUpdate="hideUpdatePaymentModal"
    />
    <post-location-modal
      :show-modal.sync="modals.showPostLocationModal"
      v-if="modals.showPostLocationModal"
      @close="hidePostLocModal"
      @closeUpdate="updateLocation"
    />
    <auto-gen-modal
      :show-modal.sync="showOpenGenModal"
      :self-paced-days.sync="selfPacedDays"
      :type="programType"
      @setSelfPace="setSelfPaceDays"
      @close="hideAutoGenModal"
      @closeUpdate="updateDaysToExclude"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { get, orderBy } from 'lodash';
import Page from '../../../components/Page.vue';

import {
  USER_ROLES,
  PROGRAM_TYPE,
  DEFAULT_PAGE_SIZE,
  SCHEDULE_TYPE_OPTIONS,
  PAYMENT_TYPE,
  CLASS_SCHEDULE_TYPES,
} from '../../../common/constants';
import {
  formatToAPIDate,
  formatFullDate,
  formatTime,
  formatTimeZone,
  formatDayDate,
  formatDateFullYear,
} from '../../../common/utils';
import { required, requiredIf, url, minLength } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';

import moment from 'moment-timezone';

import 'vue-swatches/dist/vue-swatches.css';
import ClassSchModal from './ClassSchModal.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import PostLocationModal from './PostLocationModal.vue';
import PaymentPlanModal from './PaymentPlanModal.vue';
import AutoGenModal from './AutoGenModal.vue';
import vSelect from 'vue-select';
export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    MdIcon,
    DatePicker,
    ClassSchModal,
    PostLocationModal,
    PaymentPlanModal,
    AutoGenModal,
    vSelect,
  },
  extends: Page,
  name: 'PostClass',
  data() {
    return {
      title: this.$route.params.id ? 'Edit Class' : 'Add Class',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      PAYMENT_TYPE,
      start_date: '',
      end_date: '',
      durationType: '',
      program: null,
      areProgramsLoading: false,
      allSchools: [],
      isLoading: false,
      instructors: [],
      allPrograms: [],
      USER_ROLES,
      PROGRAM_TYPE,
      location: null,
      class_link: '',
      instructor_name: '',
      available_seats: null,
      programStartTime: '',
      programEndTime: '',
      duration: '',
      programType: '',
      classSchedules: [],
      paymentPlan: [],
      paymentPlanVisible: true,
      limited_seats: true,
      orientation_date: null,
      schedule_type: '',
      scheduleTypeOptions: SCHEDULE_TYPE_OPTIONS,
      programSelected: {},
      paymentPlanAmountType: null,
      selected: [],
      showOpenGenModal: false,
      selfPacedDays: 0,
      total_hours: null,
      selfPaceStartDate: '',
      selfPaceEndDate: '',
      modals: {
        showPostLocationModal: false,
        showClassSchModal: false,
        selectedClassSch: null,
        selectedClassSchIndex: null,
        startDate: '',
        endDate: '',
        classSchArray: [],

        showClassPayModal: false,
        selectedPayment: null,
        selectedPaymentIndex: null,
        paymentStartDate: '',
        paymentEndDate: '',
        paymentArray: [],
      },
      classSelfPaced: CLASS_SCHEDULE_TYPES.SCHEDULED,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSchedules: 0,
      areInstructorsLoading: false,
      allInstructors: [],
      schdulesVisible: true,
      allLocations: [],
      areLocationsLoading: false,
      daysToExclude: [],
      windowWidth: window.innerWidth,
      typeOptions: [
        { value: 'scheduled', text: 'Scheduled' },
        { value: 'self_paced', text: 'Self-Paced' },
      ],
      CLASS_SCHEDULE_TYPES,
    };
  },

  validations() {
    return {
      program: { required },
      start_date: { required: requiredIf(() => this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED) },

      schedule_type: { required: requiredIf(() => this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED) },
      end_date: {
        required: requiredIf(() => this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED),
        afterStartDate: value =>
          !this.start_date ||
          !value ||
          moment(value, 'YYYY-MM-DD').isAfter(moment(this.start_date, 'YYYY-MM-DD')) ||
          moment(value, 'YYYY-MM-DD').isSame(moment(this.start_date, 'YYYY-MM-DD')),
      },
      instructors: { required },

      location: { required: requiredIf(() => [PROGRAM_TYPE.ONSITE, PROGRAM_TYPE.BLENDED].includes(this.programType)) },
      limited_seats: { required },
      available_seats: { required: requiredIf(() => this.limited_seats) },
      class_link: {
        required: requiredIf(() => [PROGRAM_TYPE.VIRTUAL, PROGRAM_TYPE.BLENDED].includes(this.programType)),
        url,
      },
      classSchedules: {
        required: requiredIf(() => this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED),
        minLength: minLength(1),
      },
      paymentPlan: {
        // date: { required: requiredIf(() => this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED) },
        // days: { required: requiredIf(() => this.classSelfPaced !== CLASS_SCHEDULE_TYPES.SCHEDULED) },
        calculateTotalPercentage: () =>
          this.paymentPlanAmountType === PAYMENT_TYPE.DOLLAR_AMOUNT ||
          !get(this.paymentPlan, 'length') ||
          this.paymentPlan.reduce((total, obj) => Number(obj.amount) + total, 0) === 100,
        calculateTotalAmount: () =>
          this.paymentPlanAmountType === PAYMENT_TYPE.PERCENTAGE ||
          !get(this.paymentPlan, 'length') ||
          this.paymentPlan.reduce((total, obj) => Number(obj.amount) + total, 0) ===
            get(this.programSelected, 'tuition_fee', 0),
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Classes',
          to: {
            name: 'classes-management-list',
          },
        },
        {
          text: this.$route.params.id ? 'Edit Class' : 'Add Class',
          active: true,
        },
      ];
    },
    isScheduleEmpty: ({ classSchedules }) => classSchedules.length === 0,
    isFormLoading() {
      return this.isLoading || this.areProgramsLoading;
    },

    isMobileScreen() {
      return this.windowWidth <= 420;
    },

    scheduleTableColumns() {
      return [
        { key: 'selected', label: '', thStyle: { width: '50px', maxWidth: '50px' } },
        { key: 'date', label: 'date', formatter: value => (value ? formatDayDate(value) : value) },
        {
          key: 'start_time',
          label: 'Start Time',
        },
        {
          key: 'end_time',
          label: 'End Time',
        },
        { key: 'actions', tdClass: 'text-right', thStyle: { minWidth: '100px' } },
      ];
    },
    paymentPlanTableColumns() {
      return [
        ...(this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED
          ? [{ key: 'date', label: 'Due Date', formatter: value => (value ? formatDayDate(value) : value) }]
          : [{ key: 'days_after', label: 'Days after registration' }]),
        {
          key: 'amount',
          label: 'Tuition',
        },

        { key: 'actions', tdClass: 'text-right', thStyle: { minWidth: '100px' } },
      ];
    },
  },

  methods: {
    ...mapActions('instructor', ['getAllInstructors']),
    ...mapActions('location', ['getAllLocations']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('classes', ['getClass', 'createClass', 'updateClass']),
    formatTime,
    formatDayDate,
    get,
    formatTimeZone,
    formatFullDate,
    formatDateFullYear,
    setSelfPaceDays(val) {
      this.selfPacedDays = val;
    },

    async deleteSelected() {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected schedules.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          const selectedArr = this.classSchedules.filter(x => !this.selected.filter(y => y.id === x.id).length);
          this.classSchedules = selectedArr;
          this.classSchArray = selectedArr;
          this.selected = [];
          this.daysToExclude = [];
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    updateLocation(lc) {
      this.modals.showPostLocationModal = false;
      this.allLocations.unshift(lc);
      this.location = lc.id;
    },
    hidePostLocModal() {
      this.modals.showPostLocationModal = false;
    },

    computePlanAmount(percent) {
      return (percent / 100) * get(this.programSelected, 'tuition_fee', 0);
    },

    async fetchLocation() {
      this.areLocationsLoading = true;

      const response = await this.getAllLocations({});
      this.allLocations = response.data;
      this.areLocationsLoading = false;
    },

    filterLocations(option, label, search) {
      let searchLower = search.toLowerCase();

      return (
        get(option, 'address', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'city', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'state', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'zipcode', '')
          .toLowerCase()
          .indexOf(searchLower) > -1 ||
        get(option, 'country', '')
          .toLowerCase()
          .indexOf(searchLower) > -1
      );
    },

    async fetchInstructor() {
      this.areInstructorsLoading = true;

      const response = await this.getAllInstructors({});
      this.allInstructors = response.data;
      this.areInstructorsLoading = false;
    },
    openAutoGenModal() {
      this.showOpenGenModal = true;
    },
    hideAutoGenModal() {
      this.showOpenGenModal = false;
    },
    updateDaysToExclude(data) {
      this.showOpenGenModal = false;
      this.daysToExclude = data.day;
      this.selfPacedDays = data.self;
      this.populateSchedules();
    },

    populateSchedules() {
      let currentDate = moment(this.start_date);
      const endDate = moment(this.end_date);

      let selfPaceCount = 0;
      while (selfPaceCount < this.selfPacedDays) {
        this.classSchedules.push({
          date: moment(currentDate).format('YYYY-MM-DD'),
          is_self_paced: true,
        });
        selfPaceCount++;
        currentDate = moment(currentDate).add(1, 'days');
      }
      while (currentDate <= endDate) {
        if (!this.daysToExclude.includes(moment(currentDate).day())) {
          this.classSchedules.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            start_time: this.programStartTime,
            end_time: this.programEndTime,
            is_self_paced: false,
          });
        }
        currentDate = moment(currentDate).add(1, 'days');
      }
    },

    async removeExpEduItem(index) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected schedule.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.classSchedules.splice(index, 1);
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async removePaymentItem(index) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected date from the payment plan.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          this.paymentPlan.splice(index, 1);

          if (!this.paymentPlan.length) {
            this.paymentPlanAmountType = null;
          }
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    openPaymentModal(payment = null, index = null) {
      this.modals.selectedPayment = payment;

      this.modals.selectedPaymentIndex = index;
      this.modals.showClassPayModal = true;
    },
    hideUpdatePaymentModal(data) {
      this.modals.showClassPayModal = false;
      this.modals.selectedPayment = null;
      this.modals.selectedPaymentIndex = null;

      if (data.isUpdate) {
        this.paymentPlan[data.index] = data.payment;
      } else {
        this.paymentPlan.push(data.payment);
      }
      this.paymentPlanAmountType = data.payment.amount_type;
      this.paymentPlan = orderBy(this.paymentPlan, ['date'], ['asc']);
    },
    hidePaymentModal() {
      this.modals.showClassPayModal = false;
      this.modals.selectedPayment = null;
      this.modals.selectedPaymentIndex = null;
    },

    openScheduleModal(classSch = null, index = null) {
      this.modals.selectedClassSch = classSch;
      this.modals.selectedClassSchIndex = index;
      if (this.classSchedules.length > 0) {
        this.modals.classSchArray = this.classSchedules.map(sc => sc.date);
      } else {
        this.modals.classSchArray = [];
      }

      this.modals.startDate = this.start_date;
      this.modals.endDate = this.end_date;

      this.modals.showClassSchModal = true;
    },

    hideClassSchModal() {
      this.modals.showClassSchModal = false;
      this.modals.selectedClassSch = null;
      this.modals.selectedClassSchIndex = null;
      this.modals.classSchArray = [];
    },

    hideUpdateClassSchModal(data) {
      this.modals.showClassSchModal = false;
      this.modals.selectedClassSch = null;
      this.modals.selectedClassSchIndex = null;
      this.modals.classSchArray = [];

      if (data.isUpdate) {
        this.classSchedules[data.index] = data.classSch;
      } else {
        this.classSchedules.push(...data.classSch);
      }
      this.classSchedules = orderBy(this.classSchedules, ['date'], ['asc']);
    },

    setStartDate(value) {
      if (this.program) {
        const end = moment(value).add(this.duration * (this.durationType === 'weeks' ? 7 : 1), 'days');

        this.end_date = formatToAPIDate(end);
      }
    },

    programChanged(value, firstLoad = false) {
      if (value) {
        this.programSelected = this.allPrograms.find(pg => pg.id === value);

        this.programType = this.programSelected.type;
        this.duration = this.programSelected.duration;
        this.durationType = this.programSelected.duration_type;
        this.programStartTime = get(this.programSelected, 'default_class_start_time', '');
        this.programEndTime = get(this.programSelected, 'default_class_end_time', '');
        if (!firstLoad) {
          this.selfPacedDays = this.programSelected.self_paced_days;
          this.total_hours = this.programSelected.default_hours;
          this.classSelfPaced = this.programSelected.schedule_type;
        }

        if (this.start_date && !firstLoad) {
          this.setStartDate(this.start_date);
        }
      } else {
        this.programType = '';
        this.duration = '';
        this.durationType = '';
        this.programStartTime = '';
        this.programEndTime = '';
        this.selfPacedDays = 0;
        this.total_hours = null;
        this.classSelfPaced = CLASS_SCHEDULE_TYPES.SCHEDULED;
      }
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },

    async fetchClassData() {
      try {
        if (this.$route.params.id) {
          const resp = (await this.getClass(this.$route.params.id)).data;

          this.start_date = resp.start_date;
          this.end_date = resp.end_date;
          // this.instructor_name = resp.instructor_name;
          this.program = get(resp, 'program.id', null);
          this.location = get(resp, 'class_location.id', null);
          this.class_link = get(resp, 'class_link', '');
          this.limited_seats = get(resp, 'limited_seats');
          this.schedule_type = get(resp, 'schedule_type');
          this.orientation_date = resp.orientation_date;
          this.available_seats = get(resp, 'available_seats', null);
          this.instructors = resp.instructors.map(ins => ins.id);
          this.selfPacedDays = resp.self_paced_days;
          this.total_hours = resp.total_hours;
          this.modals.startDate = resp.start_date;
          this.modals.endDate = resp.end_date;
          this.classSchedules = orderBy(get(resp, 'schedules', []), ['date'], ['asc']);
          this.paymentPlan = orderBy(get(resp, 'payment_plan', []), ['date'], ['asc']);
          this.paymentPlanAmountType = get(this.paymentPlan, '0.amount_type', null);
          this.totalSchedules = get(resp, 'schedules.length', 0);

          this.classSelfPaced = get(resp, 'class_type');
        }
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.$router.push({
          name: 'classes-management-list',
        });
      }
    },

    async onSubmit() {
      this.isLoading = true;

      if (
        this.paymentPlan.length &&
        !this.paymentPlan.every(plan =>
          this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED ? !!plan.date : !!plan.days_after
        )
      ) {
        this.makeToast({
          variant: 'danger',
          msg: `Please fill ${
            this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED ? 'date' : 'days'
          } field correctly.`,
        });
        this.isLoading = false;
        return;
      }

      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = {
            start_date:
              this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED
                ? this.start_date
                  ? formatToAPIDate(moment(this.start_date))
                  : null
                : null,
            end_date:
              this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED
                ? this.end_date
                  ? formatToAPIDate(moment(this.end_date))
                  : null
                : null,
            class_location: [PROGRAM_TYPE.ONSITE, PROGRAM_TYPE.BLENDED].includes(this.programType)
              ? this.location
              : null,
            class_link: [PROGRAM_TYPE.VIRTUAL, PROGRAM_TYPE.BLENDED].includes(this.programType)
              ? this.class_link
              : null,
            program: this.program,
            orientation_date: this.orientation_date
              ? formatToAPIDate(moment(this.orientation_date))
              : this.orientation_date,
            schedule_type: this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED ? this.schedule_type : null,
            schedules: this.classSelfPaced === CLASS_SCHEDULE_TYPES.SCHEDULED ? this.classSchedules : [],
            payment_plan: this.paymentPlan,
            instructors: this.instructors,
            limited_seats: this.limited_seats,
            available_seats: this.available_seats,
            self_paced_days: this.selfPacedDays || null,
            ...(this.total_hours && { total_hours: this.total_hours }),
            class_type: this.classSelfPaced,
          };

          if (this.$route.params.id) {
            await this.updateClass({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: this.$t('Class Updated!') });
          } else {
            await this.createClass({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'Class Added!' });
          }

          setTimeout(() => this.$router.push({ name: 'classes-management-list' }), 250);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.isLoading = true;

    await Promise.all([this.fetchPrograms(), this.fetchInstructor(), this.fetchClassData(), this.fetchLocation()]);
    this.programChanged(this.program, true);

    this.isLoading = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
